<template>
    <div class="error-screen flex-center">404: PAGE NOT FOUND</div>
</template>

<script>
  export default {
    name: 'NotFoundComponent'
  };
</script>

<style lang="scss" scoped>
  .error-screen {
    font-size: 30px;
    height: 100%;
  }
</style>
